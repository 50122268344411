body {
    margin: 0;
    padding:0;

} 

.splashHome {
    background-color: #1B1534;
    height:100%;
    background-image: url("assets/images/mobiledotsphere.png");
    width: '100vw';
    height: '100vh';
    display: "flex";
    flex: '1' ;
    justify-content: 'space-around';

    

}